import React from "react"
import ReactDOM from "react-dom"

import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

import { Social } from "./view/Social"
import { fbAuth } from "./firebase"
import { authService } from "./services/AuthService"
import { pmParent } from "./services/postMessage"

import "./index.css"
import "./view/assets/SegoeUI/stylesheet.css"

if (process.env && process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://28f6cb77568e45d69dd266b2b7135945@o987967.ingest.sentry.io/6070729",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
}

const { pathname } = window.location

switch (pathname) {
  case "/social": {
    ReactDOM.render(<Social />, document.getElementById("root"))
    break
  }
  case "/social/light": {
    ReactDOM.render(<Social light />, document.getElementById("root"))
    break
  }
  default: {
    pmParent.subscribe("auth:login", authService.login)
    pmParent.subscribe("auth:loginWithGoogle", authService.loginWithGoogle)
    pmParent.subscribe("auth:signupWithEmail", authService.signupWithEmail)
    pmParent.subscribe("auth:resetPassword", authService.resetPassword)
    pmParent.subscribe("auth:logout", authService.logout)
    pmParent.subscribe("auth:refreshToken", authService.refreshToken)

    fbAuth.onAuthStateChanged(async (fbUser) => {
      pmParent.send("auth:changed", fbUser?.toJSON())

      if (!fbUser) return

      const token = await fbUser.getIdToken(true)
      pmParent.send("auth:newToken", token)
    })
  }
}
