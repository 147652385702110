import styled from "styled-components"

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ light }) => (light ? "#ffffff" : "#2f2e31")};
  color: ${({ light }) => (light ? "rgba(0, 0, 0, 0.87)" : "white")};
  padding: 8px 36px;
  border-radius: 8px;
  font-size: ${({ light }) => (light ? "16px" : "14px")};
  font-weight: 600;
  //text-transform: uppercase;
  cursor: pointer;

  border: ${({ light }) =>
    light ? "1px solid rgba(0, 0, 0, 0.23)" : "#2f2e31"};

  &:hover {
    opacity: ${({ light }) => (light ? 1 : 0.7)};
    background-color: ${({ light }) => (light ? "#fafafa" : "#2f2e31")};
  }

  img {
    margin-right: 16px;
  }
`
