import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth"
import { fbAuth } from "../firebase"
import { pmParent } from "./postMessage"

export const authService = {
  login: async (type, { email, password }) => {
    try {
      await signInWithEmailAndPassword(fbAuth, email, password)

      pmParent.send(`${type}:fulfilled`)
    } catch (e) {
      const errors = {}

      switch (e.code) {
        case "auth/account-exists-with-different-credential":
          errors.email =
            "The email address is already in use with different credential"
          break
        case "auth/user-not-found":
          errors.email = "This email isn't registered."
          break
        case "auth/email-already-in-use":
          errors.email =
            "The email address is already in use by another account"
          break
        case "auth/argument-error":
        case "auth/wrong-password":
          errors.common = "Email or password invalid"
          break
        default:
          errors.common = "Internal error"
      }

      pmParent.send(`${type}:rejected`, errors)
    }
  },

  loginWithGoogle: async (type) => {
    try {
      // const provider = new firebase.auth.GoogleAuthProvider()
      // provider.addScope("email")
      // return await firebase.auth().signInWithPopup(provider)

      const provider = new GoogleAuthProvider()
      provider.addScope("profile")
      provider.addScope("email")

      await signInWithPopup(fbAuth, provider)

      pmParent.send(`${type}:fulfilled`)
    } catch (e) {
      pmParent.send(`${type}:rejected`, e)
    }
  },

  signupWithEmail: async (type, { email, password }) => {
    try {
      await createUserWithEmailAndPassword(fbAuth, email, password)

      pmParent.send(`${type}:fulfilled`)
    } catch (e) {
      const errors = {}

      switch (e.code) {
        case "auth/email-already-in-use":
          errors.email =
            "The email address is already in use by another account"
          break
        case "auth/argument-error":
          errors.common = "Email or password invalid"
          break
        default:
          errors.common = "Internal error"
      }
      pmParent.send(`${type}:rejected`, errors)
    }
  },

  resetPassword: async (type, { email }) => {
    try {
      await sendPasswordResetEmail(fbAuth, email)

      pmParent.send(`${type}:fulfilled`)
    } catch (e) {
      const errors = {}

      switch (e.code) {
        case "auth/user-not-found":
          errors.common = e.message
          break
        default:
          errors.common = "Internal error"
      }
      pmParent.send(`${type}:rejected`, errors)
    }
  },

  logout: async (type) => {
    try {
      await signOut(fbAuth)

      pmParent.send(`${type}:fulfilled`)
    } catch (e) {
      pmParent.send(`${type}:rejected`, e)
    }
  },

  refreshToken: async (type) => {
    try {
      const token = await fbAuth.currentUser.getIdToken(true)

      pmParent.send(`${type}:fulfilled`, token)
    } catch (e) {
      pmParent.send(`${type}:rejected`, e)
    }
  },
}
