const PREFIX_MSG = "uo.app:"
const NAME = "auth"

// const allowedOrigins = [
//   "https://app.user-onboarding.app",
//   "https://auth.user-onboarding.app",
//   "http://localhost:3030",
//   "http://localhost:3344",
// ]

export class PMService {
  target

  origin

  debug = false

  subscribes = {}

  constructor(target = window) {
    this.target = target

    this.debug = !!localStorage.getItem("ml:debug")

    this.start()
  }

  send(type, payload) {
    if (this.debug) console.log(`[PM ${NAME}] send`, type)

    this.target.postMessage(
      {
        type: this.__getType(type),
        payload,
      },
      "*"
    )
  }

  start() {
    window.addEventListener("message", this.listenerMessage.bind(this))
  }

  stop() {
    window.removeEventListener("message", this.listenerMessage.bind(this))
  }

  subscribe(type, callback) {
    if (!this.subscribes[type]) this.subscribes[type] = []

    if (this.debug) console.log(`[PM ${NAME}] subscribe`, type)

    const exist = this.subscribes[type].find((c) => c === callback)
    if (!exist) this.subscribes[type].push(callback)
  }

  unsubscribe(type, callback) {
    if (!this.subscribes[type]) return

    this.subscribes[type] = this.subscribes[type].filter((c) => c !== callback)
  }

  clear() {
    this.subscribes = []
  }

  run(type, payload) {
    if (!this.subscribes[type]) return

    this.subscribes[type].forEach((callback) => callback(type, payload))
  }

  listenerMessage(e) {
    const { data, source, origin } = e

    // if (this.debug) console.log("[PM "+NAME+"] raw msg", e)

    if (!e.isTrusted) return
    // if (source !== window) return

    // if (source !== window && !allowedOrigins.includes(origin)) {
    //   return
    // }

    const message = data
    if (
      !message ||
      typeof message !== "object" ||
      typeof message.type !== "string" ||
      !message.type.startsWith(PREFIX_MSG)
    ) {
      return
    }

    if (this.debug) console.log(`[PM ${NAME}] clear msg`, e.data)

    const type = this.__getShortType(message.type)
    this.run(type, message.payload)
  }

  __getType(type) {
    return `${PREFIX_MSG}${type}`
  }

  __getShortType(type) {
    return type.replace(PREFIX_MSG, "")
  }
}
