import React from "react"

import { authService } from "../../services/AuthService"
import GoogleSvg from "./icons/google.svg"
import * as S from "./styled"

export const Social = ({ light = false }) => {
  return (
    <div>
      <S.Button
        light={light}
        type="submit"
        onClick={() => authService.loginWithGoogle("auth:loginWithGoogle")}
      >
        <img src={GoogleSvg} alt="Google" />
        Login with your Google
      </S.Button>
    </div>
  )
}
